import s from "./Input.module.css";
import cn from "classnames";
import React, { useRef, useState, useEffect } from "react";
import { IMaskInput } from "react-imask";

import {CameraIcon, FileIcon, GetIco, PaperClipIcon, UploadFileIcon} from "@/utils/icons";
import CopyText from "@/utils/CopyText";
import { useOutside } from "@/hooks";
import SelectDays from "../SelectDays";
import Tiptap from "../Tiptap";
import Textarea from "../Textarea";
import classNames from "classnames";

export const InputNew = ({
  validate,
  className,
  classNameForBox ='',
  typeValid = "blank",
  setError = (e) => {},
  refkey,
  autoFocus,
  style,
  ...props
}) => {
  const [error, onError] = useState(false);
  useEffect(() => {
    const valid = validation(props.value, typeValid);
    setError({ value: valid, ref: refkey });
    onError(validate ? valid : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, validate]);

  return (
    <InputWrapper
      error={error}
      className={className}
      style={style}
      refkey={refkey}
      classNameForBox={classNameForBox}
      {...props}
    >
      <InputBox {...{ error, autoFocus }} classNameForBox={classNameForBox} {...props} />
    </InputWrapper>
  );
};

export const InputTextarea = ({
  validate,
  className,
  typeValid = "blank",
  setError = (e) => {},
  errorText,
  refkey,
  style,
  ...props
}) => {
  const [error, onError] = useState(false);
  useEffect(() => {
    const valid = validation(props.value, typeValid);
    setError({ value: valid, ref: refkey });
    onError(validate ? valid : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, validate]);

  return (
    <InputWrapper
      error={error}
      errorText={errorText}
      className={className}
      style={style}
      refkey={refkey}
      {...props}
    >
      <Textarea style={{ width: "100%" }} error={error} {...props} />
    </InputWrapper>
  );
};

export const InputFormate = ({
  validate,
  className,
  setError = (e) => {},
  refkey,
  style,
  ...props
}) => {
  const [error, onError] = useState(false);
  React.useEffect(() => {
    const valid = validation(props.value, "tipTap");
    setError({ value: valid, ref: refkey });
    onError(validate ? valid : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, validate]);

  return (
    <InputWrapper
      error={error}
      className={className}
      style={style}
      refkey={refkey}
      {...props}
    >
      <Tiptap
        setValue={(e) => props.onChange({ target: { value: e } })}
        error={error}
        {...props}
      />
    </InputWrapper>
  );
};

export const InputFile = ({
  validate,
  className,
  typeValid,
  setError = (e) => {},
  refkey,
  style,
  icon,
  classNameForLabel,
  ...props
}) => {
  const validateAccent = {
    img: { accept: "image/png, image/gif, image/jpeg" },
  };

  const [error, onError] = React.useState(false);
  React.useEffect(() => {
    const valid = validation(props.value, "blank");
    setError({ value: valid, ref: refkey });
    onError(validate ? valid : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, validate]);

  return (
    <InputWrapper
      error={error}
      className={className}
      classNameForLabel={classNameForLabel}
      style={style}
      refkey={refkey}
      icon={icon}
      {...props}
    >
      <InputBoxFile error={error} {...props} {...validateAccent[typeValid]} classNameForLabel={classNameForLabel} icon={icon}/>
    </InputWrapper>
  );
};

export const InputFileNewDesign = ({
                            validate,
                            className,
                            typeValid,
                            setError = (e) => {},
                            refkey,
                            placeholder = 'File',
                            style,
                            icon,
                                     isCameraIcon = false,
                                     isFileIcon=false,
                            classNameForLabel,
                                     isClipIcon = false,
                            ...props
                          }) => {
  const validateAccent = {
    img: { accept: "image/png, image/gif, image/jpeg" },
  };

  const [error, onError] = React.useState(false);
  React.useEffect(() => {
    const valid = validation(props.value, "blank");
    setError({ value: valid, ref: refkey });
    onError(validate ? valid : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, validate]);

  return (
      <InputWrapper
          error={error}
          placeholder={placeholder}
          className={className}
          classNameForLabel={classNameForLabel}
          style={style}
          refkey={refkey}
          icon={icon}
          isClipIcon={isClipIcon}
          isCameraIcon={isCameraIcon}
          isFileIcon={isFileIcon}
          {...props}
      >
        <InputBoxFileNewDesign error={error} {...props} isFileIcon={isFileIcon} placeholder={placeholder}  isCameraIcon={isCameraIcon} {...validateAccent[typeValid]} classNameForLabel={classNameForLabel} icon={icon} isClipIcon={isClipIcon}/>
      </InputWrapper>
  );
};

export const InputSelect = ({
  newDesign=false,
  finContentWidthNone=false,
  validate,
  className,
  setError = (e) => {},
  onInputSelect = () => {},
  refkey,
  style,
  ...props
}) => {
  const [error, onError] = React.useState(false);
  React.useEffect(() => {
    const valid = validation(props.value, "blank");
    setError({ value: valid, ref: refkey });
    onError(validate ? valid : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, validate]);

  return (
    <InputWrapper
      error={error}
      className={`${className} ${(newDesign && !finContentWidthNone) ? s.inputFinContentWidth : ''}`}
      style={style}
      refkey={refkey}
      {...props}
    >
      <InputBoxSelect  newDesign={newDesign} error={error} {...props} />
    </InputWrapper>
  );
};

export const InputTel = ({
  validate,
  setError = (e) => {},
  refkey,
  style,
  disabled,
  value,
  ...props
}) => {
  const bind = {
    mask: "*********************",
    onAccept: (valueP) => {
      let value = valueP?.replace(/[^\d\+\(\)\-' ']/g, '');
      
      return props.onChange({ target: { value } });
    },
    placeholder: "+7 (999) 999-99-99",
    unmask: true,
    value,
    disabled,
  };
  return (
    <InputWrapper error={validate} style={style} refkey={refkey} {...props}>
      {" "}
      <InputBoxTel
        disabled={disabled}
        error={validate}
        bind={bind}
        {...props}
      />{" "}
    </InputWrapper>
  );
};

export const InputDate = ({
  validate,
  refkey,
  setError = (e) => {},
  onChange,
  style,
  ...props
}) => {
  const [error, onError] = React.useState(false);
  React.useEffect(() => {
    const valid = validation(props.value);
    setError({ value: valid, ref: refkey });
    onError(validate ? valid : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, validate]);

  return (
    <InputWrapper error={error} style={style} refkey={refkey} {...props}>
      <SelectDays
        error={error}
        valueDefault={props.value}
        className={cn(
          s.selectDays,
          { [s.error]: validate },
          { [s.value]: props.value }
        )}
        selectDate={(e) => onChange({ target: { value: e } })}
        {...props}
        autoFocus={false}
      />
    </InputWrapper>
  );
};

export const InputCopy = ({ className, refkey, style, ...props }) => {
  return (
    <InputWrapper
      className={className}
      style={style}
      refkey={refkey}
      {...props}
    >
      <InputBoxCopy {...props} />
    </InputWrapper>
  );
};

export const InputWrapper = (props) => {
  const errorText = props.errorText ? props.errorText : "Неверные данные";
  return (
    <div
      ref={props.refkey}
      style={props.style}
      className={cn(s.wrapper, { [props.className]: props.className })}
      data-novalid={props.error}
    >
      {props.title && <h5 className={s.title}>{props.title}</h5>}
      {props.children}
      <p className={cn(s.errorText, { [s.show]: props.error })}>{errorText}</p>
    </div>
  );
};

const InputBoxCopy = (props) => {
  const [val, setVal] = React.useState(props.value);
  const ref = useRef();
  const copy = () => {
    CopyText(props.value).catch(() => {
      ref.current.select();
      document.execCommand("copy");
    });
    setVal("Скопировано");
    setTimeout(() => setVal(props.value), 3000);
  };
  return (
    <div className={cn(s.inputBox, s.InputBoxCopy)} onClick={copy}>
      <input ref={ref} className={cn(s.input)} value={val} readOnly />
      <GetIco className={s.ico} icon="restore_down" />
    </div>
  );
};

const InputBox = ({ error, errorText, autoFocus, classNameForBox = '', ...props }) => {
  const ref = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ref.current.focus();
      }, 100);
    }
  }, []);

  return (
    <div
      className={cn(
        s.inputBox,
        { [s.disabled]: props.disabled },
        { [s.error]: error },
        classNameForBox,
      )}
    >
      <input ref={ref} className={cn(s.input, classNameForBox)} {...props} />
    </div>
  );
};

const InputBoxTel = ({ error, errorText, bind, ...props }) => {
  return (
    <div
      className={cn(
        s.inputBox,
        { [s.disabled]: props.disabled },
        { [s.error]: error }
      )}
    >
      <IMaskInput {...bind} className={s.input} />
    </div>
  );
};

const InputBoxFile = ({
  error,
  errorText,
  value,
  placeholder = "Выберите файл",
  onChange,
  classNameForLabel,
                        newDesign = false,
  icon = 'upload',
  ...props
}) => {
  const ref = useRef();
  const remove = (e) => {
    e.preventDefault();
    onChange({ target: { value: "" } });
  };
  const removeItem = (e, index) => {
    e.preventDefault();
    onChange({ target: { value: value.filter((_, i) => i !== index) } });
  };

  const input = (e) => {
    const files = ref.current.files;
    if (files) {
      if (!props.multiple) onChange({ target: { value: files[0] } });
      else {
        const arFiles = [];
        for (let i = 0; i < files.length; i++) {
          arFiles.push(files[i]);
        }
        onChange({ target: { value: arFiles } });
      }
    } else if (!props.multiple) onChange({ target: { value: "" } });
    ref.current.value = "";
  };

  return (
    <label
      className={cn(
        s.inputBox,
        s.inputBoxFile,
        { [s.disabled]: props.disabled },
        { [s.error]: error },
        {[s.onlyIcon]: classNameForLabel },
          {[s.newDesign]: newDesign}
      )}
    >
      {value?.length === 0 && (
        <span className={s.placeholder}>{placeholder}</span>
      )}
      {!props?.multiple && value?.name ? (
        <span className={s.input}>{value?.name}</span>
      ) : (
        !props?.multiple &&
        value && (
          <a
            href={process.env.REACT_APP_PATH_FILE + value}
            target="_blank"
            className={s.input}
            rel="noreferrer"
          >
            {value?.split("/")?.pop()}
          </a>
        )
      )}

      {props.multiple && typeof value === "object" && value.length > 0 && (
        <div className={s.inputFiles}>
          {value?.map((item, index) => (
            <span key={index} className={s.inputFileWrap}>
              {item?.name ? (
                <span className={s.inputFileName}>{item?.name}</span>
              ) : (
                <a
                  href={process.env.REACT_APP_PATH_FILE + item}
                  target="_blank"
                  className={s.inputFileName}
                  rel="noreferrer"
                >
                  {item?.split("/")?.pop()}
                </a>
              )}
              <GetIco
                className={s.ico}
                icon='cross'
                onClick={(e) => removeItem(e, index)}
              />
            </span>
          ))}
        </div>
      )}

      <input
        {...props}
        ref={ref}
        type="file"
        className={cn(s.inputFile)}
        onChange={input}
      />
      {!props.disabled && value?.length === 0 && (
        <GetIco className={s.ico} icon={icon} />
      )}
      {!props.disabled && value?.length !== 0 && (
        <GetIco className={s.ico} icon="bin" onClick={remove} />
      )}
    </label>
  );
};

const InputBoxFileNewDesign = ({
                        error,
                        errorText,
                        value,
                        placeholder = "Выберите файл",
                        onChange,
                        classNameForLabel,
                        newDesign = false,
                        icon = 'upload',
                        isCameraIcon = false,
                        isClipIcon =false,
                                 isFileIcon =false,
                        ...props
                      }) => {
  const ref = useRef();
  const remove = (e) => {
    e.preventDefault();
    onChange({ target: { value: "" } });
  };
  const removeItem = (e, index) => {
    e.preventDefault();
    onChange({ target: { value: value.filter((_, i) => i !== index) } });
  };

  const input = (e) => {
    const files = ref.current.files;
    if (files) {
      if (!props.multiple) onChange({ target: { value: files[0] } });
      else {
        const arFiles = [];
        for (let i = 0; i < files.length; i++) {
          arFiles.push(files[i]);
        }
        onChange({ target: { value: arFiles } });
      }
    } else if (!props.multiple) onChange({ target: { value: "" } });
    ref.current.value = "";
  };

  return (
      <label
          className={cn(
              // s.inputBox,
              // s.inputBoxFile,
              { [s.disabled]: props.disabled },
              { [s.error]: error },
              {[s.onlyIcon]: classNameForLabel },
              {[s.newDesign]: newDesign}
          )}
      >
        {value?.length === 0 && (
            <span className={s.placeholder}>{placeholder}</span>
        )}
        {!props?.multiple && value?.name ? (
            <span className={s.input} style={{fontSize: '10px'}}>{value?.name}</span>
        ) : (
            !props?.multiple &&
            value && (
                <a
                    href={process.env.REACT_APP_PATH_FILE + value}
                    target="_blank"
                    className={s.input}
                    rel="noreferrer"
                >
                  {value?.split("/")?.pop()}
                </a>
            )
        )}

        {props.multiple && typeof value === "object" && value.length > 0 && (
            <div className={s.inputFiles}>
              {value?.map((item, index) => (
                  <span key={index} className={s.inputFileWrap}>
              {item?.name ? (
                  <span className={s.inputFileName}>{item?.name}</span>
              ) : (
                  <a
                      href={process.env.REACT_APP_PATH_FILE + item}
                      target="_blank"
                      className={s.inputFileName}
                      rel="noreferrer"
                  >
                    {item?.split("/")?.pop()}
                  </a>
              )}
                    <GetIco
                        className={s.ico}
                        icon='cross'
                        onClick={(e) => removeItem(e, index)}
                    />
            </span>
              ))}
            </div>
        )}

        <input
            {...props}
            ref={ref}
            type="file"
            className={cn(s.inputFile)}
            onChange={input}
        />
        {!props.disabled && value?.length === 0 &&  !isClipIcon &&  !isCameraIcon && !isFileIcon && (
           <UploadFileIcon />
        )}
        {!props.disabled && value?.length === 0 &&  isClipIcon &&  !isCameraIcon && !isFileIcon && (
            <PaperClipIcon />
        )}
        {!props.disabled && value?.length === 0 &&  isCameraIcon && !isClipIcon && !isFileIcon &&(
            <CameraIcon  width={'24'} height={'24'}/>
        )}
        {!props.disabled && value?.length === 0 &&  !isCameraIcon && !isClipIcon && isFileIcon && (
            <FileIcon  width={'24'} height={'24'}/>
        )}
        {!props.disabled && value?.length !== 0 && (
            <GetIco className={classNames(s.ico, s.icoNewDesign)} icon="bin" onClick={remove} />
        )}
      </label>
  );
};

const InputBoxSelect = ({
  error,
  errorText,
  options,
  placeholder = "Выберите",
  errorOptions = "Нет данных",
  all = false,
  newDesign = false,
  popupClassName,
  ...props
}) => {
  const [ref, isShow, setIsShow] = useOutside();
  const [selectAll, setAll] = React.useState(false);

  const isSelectAll = (value) => {
    const valuesAll = [...props.value];
    if (valuesAll.includes(value)) return false;
    valuesAll.push(value);
    if (valuesAll.length !== options.length) return false;
    return true;
  };

  const input = (e, value) => {
    if (props.multiple) {
      e.stopPropagation();
      if (all) setAll(isSelectAll(value));
    }

    if (!props.multiple) props?.onChange({ target: { value } });
    else if (props?.value?.includes(value))
      props.onChange({
        target: { value: props?.value?.filter((item) => item !== value) },
      });
    else props?.onChange({ target: { value: [...props.value, value] } });
  };

  const inputAll = (e) => {
    e.stopPropagation();
    if (selectAll) {
      setAll(false);
      props?.onChange({ target: { value: [] } });
    } else {
      props?.onChange({ target: { value: options.map(({ value }) => value) } });
      setAll(true);
    }
  };

  return (
    <div
      ref={ref}
      className={cn(
        s.inputBox,
        s.InputBoxSelect,
        { [s.show]: isShow },
        { [s.disabled]: props.disabled },
        { [s.error]: error },
        {['backgroundNone']: newDesign}
      )}
      onClick={() => !props.disabled && setIsShow(!isShow)}
    >
      {(props?.value?.length === 0 || props?.value === null) && (
        <span style={{textWrap: newDesign ? 'noWrap' : 'wrap'}} className={s.placeholder}>{placeholder}</span>
      )}

      {!props?.multiple && props?.value && (
        <span className={s.input}>
          {options?.find((i) => i.value === props.value)?.label || props.value.name}
          {/*{options?.find((i) => i.label === props.value.name)?.label || props.value.name}*/}
        </span>
      )}

      {props?.multiple &&
        props?.value?.length > 0 &&
        props?.value?.length !== 0 && (
          <span className={s.input}>{props?.printValues ? 'Варианты' : 'Выбрано'}: {props?.printValues ? props?.value?.toString()?.replace(/,/g, '; ') + ';' : props?.value?.length}</span>
        )}
     
      {newDesign ? <GetIco className={`${s.icoNewDesign} ${s.ico}`} icon="angle_arrow_small_down_new_design" /> : <GetIco className={s.ico} icon="angle_arrow_small_down" />}
      

      <div
        className={cn(s.selectListWrap, newDesign ? s.selectListWrapNewDesign : '', { [s.show]: isShow }, popupClassName, {
          [s.large]: options?.length > 12,
        })}
      >
        <div
          className={cn(s.selectList, "customScroll", {
            [s.large]: options?.length > 12,
          })}
        >
          {(!options || options?.length < 1) && (
            <span className={s.textError}>{errorOptions}</span>
          )}
          {options?.length > 1 && all && props.multiple && (
            <span
              onClick={(e) => inputAll(e)}
              className={cn(s.selectItem, { [s.selected]: selectAll })}
            >
              Все
            </span>
          )}

          {options?.map((item, idx) => (
            <span
              key={`${idx}-${item.value}`}
              onClick={(e) => input(e, item.value)}
              className={cn(s.selectItem, {
                [s.selected]: props.multiple
                  ? props.value?.includes(item.value)
                  : props.value === item.value,
              })}
            >
              {item.label}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const validation = (value, type = "blank") => {
  if (typeof type === "function") {
    return type(value);
  }

  switch (type) {
    case "blank":
      return value === "" ? true : false;
    case "tipTap":
      return value === "" ||
        (value?.content?.length > 0 && value?.content[0]?.content === undefined)
        ? true
        : false;
    case "name":
      return value?.split(" ")?.length > 1 && value?.split(" ")[1]
        ? false
        : true;
    default:
      return false;
  }
};
