import s from './index.module.css'
import style from '../../index.module.css'
import UploadFile from '../../../../../UI/UploadFile'
import { useEffect, useState } from 'react'
import { ButtonDel } from '../../../../../UI/Button'
import classNames from 'classnames'
import { homeTaskApi } from '../../../../../../api'

export const Audios = ({ data, edit }) => {
  const [fileList, setFilterList] = useState(data?.homeTaskFiles?.filter(item => item.type === 'audio'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setFilterList(data?.homeTaskFiles?.filter(item => item.type === 'audio')) }, [])
  const removeFile = id => homeTaskApi.removeFile(id).then(res => {
    if (res.data.status === 'success') setFilterList(prev => [...prev.filter(item => item.id !== id)])
  })


  const add = () => edit(prev => ({ ...prev, audio: [...prev.audio, { file: {} }] }))
  const remove = i => edit(prev => ({ ...prev, audio: [...prev.audio.filter((_, index) => index !== i)] }))
  const input = (val, i) => edit(prev => ({
    ...prev, audio: [...prev.audio.map((item, index) => ((i === index) ? { file: val } : item))]
  }))

  return <div className={s.wrapper}>
    <span className={style.heading}>Аудио:</span>
    <div className={s.list}>
      {fileList?.map((item, index) => <Item key={index} index={index} value={item.file_path} input={input} showMode={true} remove={() => removeFile(item.id)} />)}
      {data.audio?.map((item, index) => <Item key={index} index={index} value={item.file} input={input} remove={() => remove(index)} />)}
      <button className={classNames(style.btnAdd, s.btnAdd)} onClick={add}>+</button>
    </div>
  </div >
}

const Item = ({ index, value, input, remove, showMode = false }) => {
  return <div className={s.row}>
    <UploadFile className={s.file} value={value} onChange={val => input(val, index)} showMode={showMode} />
    <ButtonDel className={s.btnRemove} onClick={remove} />
  </div>
}
