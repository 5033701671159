import s from "./index.module.css";
import { GetIco } from "../../../utils/icons";
import onCorKey from "../../../utils/onKeyUp";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

const UploadFile = ({
  value = '',
  noIco = false,
  className = "",
  title = "Загрузите файл",
  icon = "loading",
  onInput = () => { },
  onChange = (file) => { },
  allowed = true,
  showMode = false,
  ...props
}) => {
  const inputFile = useRef()
  let [setting, setSetting] = useState({ label: value?.name ? value.name : title, file: value })
  useEffect(() => { setSetting({ label: value?.name ? value.name : title, file: value }) }, [value, title])

  const input = (e) => {
    if (e.target.value) {
      let file = e.target.files[0]
      setSetting({ label: file.name, file: file })
      onInput(e)
      onChange(file)
    }
  }

  const reset = (e) => {
    setSetting({ label: title, file: null })
    onInput(e)
    onChange('')
    inputFile.current.value = ''
  }

  const setOpen = (e) => {
    e.target.firstChild.click();
  }

  if (showMode) return <ShowMode value={value} className={className} />

  return <div className={classNames(s.wrapper, { [s.disable]: !allowed }, { [className]: className })}
    data-file={setting.file ? true : false} tabIndex={0} onKeyUp={(e) => onCorKey(e) && setOpen(e)} >
    <label>
      <span>{setting.label}</span>
      <input ref={inputFile} type="file" onInput={input} disabled={!allowed} {...props} />
    </label>
    {setting.file ? !noIco && <button className={classNames({ [s.disable]: !allowed })} disabled={!allowed}> <GetIco icon="cross" onClick={reset} /> </button>
      : <GetIco icon={icon} />
    }
  </div>
}

const ShowMode = ({ value, className }) => {
  return <div className={classNames(s.wrapper, s.showMode, { [className]: className })}>
    <a href={process.env.REACT_APP_PATH_FILE + value} target='_blank' rel="noreferrer" >Открыть файл</a>
    <GetIco icon='paper' />
  </div>
}

export default UploadFile;
