import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import s from "./index.module.css";
import HeaderPage from "../../common/HeaderPage";
import img from './img/img.jpg'
import img2 from './img/img2.jpeg'
import Button from "../../UI/Button";
import getVideoPlayer from '@/helpers/getVideoPlayer';
import Textarea from "../../UI/Textarea"
import {useState, useEffect, useRef, Fragment} from 'react';
import { selectIsAuth } from "@store/profile.reducer";
import { useSelector } from "react-redux";
import {news as newsApi} from "../../../api"
import {useAddAlert} from "@/hooks/useAddAlert";
import Preloader from '../../UI/Preloader';
import Moment from 'react-moment';
import moment from "moment";
import 'moment/locale/ru';
import { selectProfile } from '@store/profile.reducer'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import JsonPars from "../../UI/JsonPars/JsonPars";
moment.locale('ru')

const WrapperNewsViewing = () => {
    let isAuth = useSelector(selectIsAuth);
    if(isAuth){
        return(
            <NewsViewing />
        )
    }
    else{
        return(
            <div style={{marginTop:'30px'}} className={s.pageNews}>
                <NewsViewing />
            </div>
        )
    }
}
const NewsViewing = () => {
    const profile = useSelector(selectProfile)
    let isAuth = useSelector(selectIsAuth);
    // const checkingRoleExistence = () => {
    //     switch (profile?.role) {
    //         case 'children': return true;
    //         case 'admin': return true;
    //         case 'personal-manager': return true;
    //         case 'hr-manager': return true;
    //         case 'seminarian': return true;
    //         case 'senior-seminarian': return true;
    //         default: return false;
    //     }
    // } 

    //const [authorized, setAuthorized] = useState('')
    // useEffect(()=>{
    //     setAuthorized(isAuth);
    // }, [])

    const addAlert = useAddAlert();
    const [loading, setLoading] = useState(true)
    const { id } = useParams()

    const [newComment, detNewComment] = useState('');
    const enteringComment = (value) => {
        detNewComment(value)
    }
    const [loadingAddComment, setLoadingAddComment] = useState(false);
    const [loadingComments, setLoadingComments] = useState(true);

    

    const addComment = () => {
        let formData = new FormData(); 
        formData.append('comment', newComment);
        setLoadingAddComment(true)
        newsApi.createComment(id, formData)
       
        .then((res) => {
            if (res.status === 200) {
                detNewComment('')
                addAlert({text: 'Комментарий успешно добавлен'})
                getComments(true)
                setThereAreMoreComments(true)
                setNumberOfComments((state)=>{
                    return state + 1;
                })
            } else {
                addAlert({ title: 'Ошибка', text: 'При добавлении комментария произошла ошибка', type: 'error' })
            }
        }).finally(()=>{
            setLoadingAddComment(false)
        });
    }
    const [news, setNews] = useState({});
    const [comments, setComments] = useState([]);
    useEffect(()=>{
        setNumberOfComments(news?.comments)
    },[news?.comments])
    useEffect(()=>{
        getNews()
        getComments()
    }, []);
    const linkToComments = useRef(null);
    const [alreadyScrolled, setAlreadyScrolled] = useState(false)
    useEffect(()=>{
        if(!loading && !loadingComments){
            if(localStorage.getItem("scrollToComments") && localStorage.getItem("scrollToComments") == '1'){
                if(!alreadyScrolled){
                    setAlreadyScrolled(true)
                    localStorage.setItem("scrollToComments", "0")
                    linkToComments.current.scrollIntoView({block: "center", inline:"center", behavior: "smooth"});
                }
            }
        }
    }, [loading, loadingComments])
   
    //начало подгрузки новых комментариев
    const [thereAreMoreComments, setThereAreMoreComments] = useState(true)//есть ли ещё комментарии
    const [numberOfLoadedComments, setNumberOfLoadedComments] = useState(0);//количество сейчас загруженных на странице
        //проверяем дошли ли мы до конца страницы
        const checkBottom = () => {
            const bottom = document.body.scrollHeight;
            const height = window.innerHeight;
            const current = Math.round(window.scrollY);
            if(thereAreMoreComments){
                if (current + height + 0 >= bottom && !loadingComments){
                    if(!loadingComments){
                        getComments()
                    }
                    
                };
            }
           
        };

        //подписываемся на обновление
        useEffect(() => {
            document.addEventListener('scroll', checkBottom, true);
            return () => document.removeEventListener('scroll', checkBottom, true);
        });
    //конец подгрузки

    const getNews = () => {
        setLoading(true)

        fetch(`${process.env.REACT_APP_URL_API}news/view/${id}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
            setNews(data)
        })
        .catch(()=>{
            addAlert({ title: 'Ошибка', text: 'При получении новости произошла ошибка', type: 'error' })
        })
        .finally(() => {
            setLoading(false)
        });
    } 

   

    const [numberOfComments, setNumberOfComments] = useState(0);
    const getComments = (update=false) =>{
        // if(loadingComments) return;
        if(update){
            setNumberOfLoadedComments(0)
        }
        setLoadingComments(true)
        
        
        fetch(`${process.env.REACT_APP_URL_API}news/comments/${id}?first=${update ? 0 : numberOfLoadedComments}&last=${update ? 5 : numberOfLoadedComments + 5}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
            setNumberOfLoadedComments(update ? 5 : numberOfLoadedComments + 5)
               
                setComments(prevComments => {
                    if(update){
                        const combinedComments = [...data];  
                        return combinedComments;
                    }
                    else{
                        const combinedComments = [...prevComments, ...data];
                        return combinedComments;
                    } 
                    // const uniqueComments = Array.from(new Set(combinedComments.map(comment => comment.id)))
                    //     .map(id => combinedComments.find(comment => comment.id === id));
                });
                if(data?.length < 5){
                    setThereAreMoreComments(false)
                }
        })
        .catch(()=>{
            addAlert({ title: 'Ошибка', text: 'При получении комментариев произошла ошибка', type: 'error' })
        })
        .finally(() => {
            setLoadingComments(false)
        });
    }

   
    const textareaOpenBlock = () => ({
        onInput: e => {
            enteringComment(e?.target?.value)
        },
        className: s.inputNew,
        placeholder: 'Введите комментарий',
        value: newComment
    })

    const deleteComment = (id) => {
        newsApi.deleteСomment(id)
        .then((res) => {
            if (res.status === 200) {
                addAlert({text: 'Комментарий успешно удален'})
                setNumberOfComments((state)=>{
                    return state - 1;
                })
                const newComments = comments?.filter((el)=> {
                    return el?.id != id;
                })
                setComments(newComments)
            } else {
                addAlert({ title: 'Ошибка', text: 'При удалении комментария произошла ошибка', type: 'error' })
            }
        })

    }

    

    if(news?.authorized_only == 1 && !isAuth){
        return  <main className={`page-content ${s.page}`}>
            <div className={s.authorized}>Эта новость доступна только для авторизованных пользователей</div>
        </main>
    }

    return (
    <>
    {/* {loading ? <></> : <HeaderPage
        title={news?.title}
        part={['', news?.title]}
    /> } */}
     
        <main className={`page-content ${s.page}`}>
        {
            loading ? <Preloader fullScreen='center' /> : <>
             <>
            { news?.newsBlocks?.map((elem, index, mass )=>{
                return <View key={index} mass={mass} elem={elem} index={index} {...elem}/>;
            })}
            </>
            
           <div className={s.comments} ref={linkToComments}>
                <h2 style={{textAlign:'left'}}>Комментарии</h2>
                {isAuth ? <>
                    <div className={s.commentsTextarea}>
                    <Textarea {...textareaOpenBlock()} />
                </div>
                <Button disable={loadingAddComment} onClick={addComment} styles="accent">Добавить комментарий</Button></> : <></>}
                
                
                <div className={s.numberOfComments}>
                    {numberOfComments} комментариев 
                </div>

                <TransitionGroup className={s.commentsList} component={'div'}>
                    {
                            comments?.map((el)=>{
                                return(
                                    <CSSTransition
                                    key={el?.id}
                                    timeout={700}
                                    classNames="itemAnimation"
                                    >
                                        <div className={s.commentsListItem}>
                                            {profile?.role==='admin' ? <div onClick={() => {deleteComment(el?.id)}} className={s.delete}>Удалить</div> : <></> }
                                            <div className={s.commentsListItemAvatar}>
                                                <img src={el?.img} alt="" />
                                            </div>
                                            <div className={s.commentsListItemText}>
                                                <div className={s.commentsListItemTextNameAndDate}>
                                                    <div className={s.commentsListItemTextName}>{el?.name}</div>
                                                    <div className={s.commentsListItemTextDate}><Moment locale="ru" date={el?.date} format="D MMM YYYY"  withTitle /></div>
                                                </div>
                                                <div className={s.commentsListItemTextContext}>{el?.comment}</div>
                                            </div>
                                        </div>
                                    </CSSTransition>
                                )
                            })
                    }
                </TransitionGroup>
               
                    
                    
                
           </div>
            </>
        }
           
           
        </main>
    </>
    )
}
export default WrapperNewsViewing;

const View = ({id, type, bigTitle, title, text, width, image, href, hrefText, youTubeVideo, mass, elem, index }) => {
    const viewYoutu = (data) => {
        const { type, url } = getVideoPlayer(data);
        return { type, url };
    }
    if(type == "bigTitle"){
        return <h2><JsonPars json={elem.big_title} /></h2>;
    }
    if(type == "text"){
        return  <p><JsonPars json={elem.text} /></p>;
    }
    if(type == "title"){
        return  <h3><JsonPars json={elem.title} /></h3>;
    }
    if(type == "img"){
        return (<div style={{width:`${elem.width}%`}} className={s.img}>
            <img src={elem.image_type && elem.image ? 'data:image/' + elem.image_type + ';base64,' + elem.image : ''} alt="" />
        </div>);
    }
    if(type == "imgLeftText"){
        return (<div className={s.imgLeftText}>
            <div className={s.imgLeft}>
                <img src={elem.image_type && elem.image ? 'data:image/' + elem.image_type + ';base64,' + elem.image : ''} alt="" />
            </div>
            <div className={s.imgText}>
                <p><JsonPars json={elem.text} /></p>
            </div>
        </div>);
    }
    if(type == "imgRightText"){
        return (<div className={s.imgRightText}>
            <div className={s.imgText}>
            <p><JsonPars json={elem.text} /></p>
            </div>
            <div className={s.imgRight}>
                <img src={elem.image_type && elem.image ? 'data:image/' + elem.image_type + ';base64,' + elem.image : ''} alt="" />
            </div>
        </div>);
    }
    if(type == "href"){
        return (
            <>
            {mass[index-1]?.type=='href' ? <></> : <Hrefs mass={mass} elem={elem} index={index} />}
            </>
        )
    }
    if(type == "video"){
        if(viewYoutu(elem.video)?.type=='youtube'){
            return (
                <div className={s.videoWrapperFirst} style={{width:`${width}%`}}>
                    <div className={s.videoWrapper}>
                        <iframe src={'https://www.youtube.com/embed/' + viewYoutu(elem.video)?.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            )
        }
        if(viewYoutu(elem.video)?.type=='error'){
             return (
                <div className={s.videoWrapperFirst} style={{width:`${width}%`}}>
                    <div class={s.videoWrapper}>
                        <iframe src={viewYoutu(elem.video)?.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            )
        }
        return <></>;
        
    }
   
    
    
    
}

const Hrefs = ({elem, index, mass})=>{
    return(
        <div className={s.linkBlock}>
           <Hrefs2 mass={mass} elem={elem} index={index}/>
        </div>
    )
}
const Hrefs2 = ({elem, mass, index}) =>{
    
    return(<>
        {
            mass?.map((el, ind, mas)=>{
                if(ind==index){
                   
                    return(<Fragment key={ind}>
                    <a className={s.link} href={mas[ind]?.href} target='_blank'><Button styles="accent">{mas[ind]?.href_text}</Button></a>
                    {mass[index+1]?.type== "href" ? <Hrefs2 mass={mass} elem={elem} index={ind + 1}/> : <></>}
                    </Fragment>
                    )
                }
            })
        }
    </>
    )
    
}