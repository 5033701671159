import LayoutAuth from '@common/LayoutAuth'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { AuthService } from '@/services/AuthService'
import s from './index.module.css'
import { SetTitlePage } from '@common/SetTitlePage'
import { Button, Form, Input } from 'antd'
import classNames from 'classnames'
import DevPanel from '@/dev/components/DevPanel'
import { Config, Connect, ConnectEvents } from '@vkontakte/superappkit';
import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";
const Auth = () => {
  const [settings, setSettings] = useState();

  useEffect(() => {
      setSettings(SettingsService.get(SETTINGS_TYPES.GENERAL));
  }, []);

  const hist = useHistory()

  const [loading, setLoading] = useState(false)
  const initError = { status: false, text: '', inputs: {} }
  const [error, setError] = useState(initError)

  const getValueFrom = () => setError(initError)

  const login = async (data) => {
    setLoading(true)
    const res = await AuthService.login(data.email, data.password)
    setLoading(false)

    if (res === 'ok') hist.push('/')
    else setError({ status: true, text: 'Не верная почта или пароль', inputs: { getValueFromEvent: getValueFrom } })
  }

  const onFinish = (data) => login(data)

  const VkAuth = () => {
    Config.init({
      appId: process.env.REACT_APP_VK_APP_ID,
    });

    let url = `${window.location.protocol}//${window.location.hostname}`

    if (window.location.port) {
      url = url + ':' + window.location.port
    }

    Connect.redirectAuth({
      url: `${url}/signup-vk`,
      state: ''
    })
  }

  return <LayoutAuth>
    <SetTitlePage title='Авторизация' />
    <div className={classNames('new_card', s.card)}>

      <h2 className={s.title}>Войдите в свой профиль</h2>

      <Form {...{ onFinish }}>

        <Form.Item name='email' rules={[{ required: true, message: 'Пожалуйста, введите e-mail или номер телефона' }]} {...error.inputs}>
          <Input placeholder='Электронная почта или номер телефона' disabled={loading} />
        </Form.Item>

        <Form.Item name='password' rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]} {...{ ...error.inputs, noStyle: error.status }}>
          <Input.Password placeholder='Пароль' disabled={loading} />

        </Form.Item>

        {error.status && <Form.Item>
          <div className="ant-form-item-explain-error">{error.text}</div>
        </Form.Item>}


        <Form.Item>
          <div className={s.buttons}>
            <Button htmlType="submit" className={s.buttonLogin} {...{ loading }}>
              Войти
            </Button>
            {
              settings?.isDisableloginViaVK === '0' ? <Button htmlType="button" className={s.buttonLoginVk} onClick={VkAuth}>
              <span>
                Войти через <span className={classNames('social social--vk', s.buttonLoginVkIco)}></span>
              </span>
            </Button> : <></>
            }
           
          </div>
        </Form.Item>

        <div className={s.bottomLinks}>
          {
            settings?.isDisableRegistration === '1' ? <></> : <Button type='link' style={{ float: 'right' }} onClick={() => { hist.push('/register') }}>Зарегистрироваться</Button>
          }
          
          <Button type='link' onClick={() => { hist.push('/restore-password') }}>Восстановить пароль</Button>
        </div>

      </Form>

      {process.env.REACT_APP_DEV_MODE === '1' && <DevPanel login={login} />}
    </div>
  </LayoutAuth>
}

export default Auth
