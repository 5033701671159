import s from './ChatMessage.module.css'
import {ClipIcon, DeleteChatIcon, DotsIcon} from "@/utils/icons";
import Moment from 'react-moment';
import moment from "moment";
import 'moment/locale/ru';
import UserImage from "@common/Chat/components/UserImage/UserImage";
import React, {useRef, useState} from "react";
import classNames from "classnames";
import {chatApi} from "@/api";
import {getAvatar} from "@common/Chat/helpers";
import {Image} from "antd";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";
import {useAddAlert} from "@/hooks/useAddAlert";
import rolesEnum from "@/constant/rolesEnum";
moment.locale('ru')

const ChatMessage = ({
     message,
     data,
     setAnswer,
     setAnswerAuthor,
     fixed,
     setIsNeedUpdate,
     fixedRef,
     activeUsers,
     onDeleteMessage = new Function()
}) => {
    const addAlert = useAddAlert()
    const profile = useSelector(selectProfile);
    const member = data?.members?.find(el => el.user_id === profile.id)
    const myRole = member?.role
    const [isActive, setIsActive] = useState(false)
    const openMenu = (e) => {
        e.stopPropagation()
        setIsActive(!isActive)
    }

    const parentMessage = message?.parentId ? data?.messages.find(el => el.id === message?.parentId) : null
    const parentMessageAuthor = parentMessage ? data?.members.find(el => el.member_id === parentMessage?.author) : null
    const menuRef = useRef(null)
    moment.locale('ru' )
    const authorName = data?.members?.find(member => message.author === member.member_id)
    const isUserOnline = activeUsers.filter(user => user.id == authorName.user_id);
    const handleClick = () => {
        setAnswer(message)
        setAnswerAuthor(authorName)
    }

    const handleClip = (e) => {
        e.stopPropagation()
        setIsActive(false)
        chatApi.fixMessage(message.id).then((res) => setIsNeedUpdate(true))
    }

    const handleRemoveMessage = (e) => {
        e.stopPropagation()
        setIsActive(false)
        onDeleteMessage(message)

        chatApi
            .removeMessage(message.id)
            .then(({response}) => {
                if (response?.data?.status === 'error') {
                    addAlert({type: 'error', title: response?.data?.error})
                }
            })
    }

    const isNeedRef = fixed === message.id;

    const nadleClick =(e) => {
        e.stopPropagation()
    }
   
    return (
        <div className={s.message} onClick={handleClick} ref={isNeedRef ? fixedRef : null}>
            <div className={s.messageWr}>
                <div className={s.photo}>
                    <UserImage path={authorName.avatar}/>
                    {isUserOnline.length === 1 && <div className={s.isOnline}></div>}
                </div>
                <div className={s.chatBody}>
                    <div className={s.chatHead}>
                        <div className={s.authorName}>
                            {authorName.name}
                        </div>
                        <div className={s.date}>
                            <Moment locale="ru" date={message?.createdAt} format="D MMM YYYY k:mm"  withTitle />
                        </div>
                    </div>
                    {parentMessage && (
                        <div className={s.answer}>
                            <div className={s.answerAuthor}>
                                {parentMessageAuthor?.name}
                            </div>
                            <div className={s.answerText}>
                                {parentMessage?.message}
                            </div>
                        </div>
                    )}
                    <div className={s.text}>
                        {JSON.parse(message.message)}
                    </div>
                </div>
                {myRole === 1 && <>
                    <div className={s.dots} onClick={(e) => openMenu(e)}><DotsIcon/></div>
                    <div className={classNames(s.menu, isActive ? s.active : '')} ref={menuRef}
                         onMouseLeave={() => setIsActive(false)}>
                        <div className={s.menuRow} onClick={handleClip}>
                            <div className={s.menuIcon}><ClipIcon width={'16'} height={'16'}/></div>
                            <div className={s.menuName}>Закрепить сообщение в чате</div>
                        </div>
                        {profile.role === rolesEnum.admin || member?.member_id === message.author &&
                          <div className={s.menuRow} onClick={handleRemoveMessage}>
                              <div className={s.menuIcon}><DeleteChatIcon width={'16'} height={'16'}/></div>
                              <div className={s.menuName}>Удалить сообщение</div>
                          </div>
                        }
                    </div>
                </>}
            </div>
            {message?.files && (
              <div className={s.messageImages}>
                  {message.files.map((file, i) => (
                    file.type === 'image/png' || file.type === 'image/jpeg'
                      ? <Image.PreviewGroup key={file.id}>
                        <Image
                            width={200}
                            height={200}
                            src={getAvatar(file.path, '')}
                            style={{ borderRadius: 14 }}
                            onClick={(e) =>nadleClick(e)}
                        />
                      </Image.PreviewGroup>
                      : <a onClick={(e) => e.stopPropagation()}
                           className={s.messageLink} href={process.env.REACT_APP_PATH_FILE + file.path}
                           target={'black'}
                           rel={'noopener noreferrer'}
                           key={file.id}
                      >
                          {file?.original}
                      </a>
                    ))}
                </div>
            )}
        </div>
    )
}
export default ChatMessage;