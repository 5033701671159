const rolesEnum = {
    admin:                 'admin',
    adminPanel:            'adminPanel',
    children:              'children',
    hrManager:             'hr-manager',
    htPanel:               'htPanel',
    managerPanel:          'managerPanel',
    personalManager:       'personal-manager',
    seminarian:            'seminarian',
    seniorSeminarian:      'senior-seminarian',
    seminarianPanel:       'sPanel',
    seniorSeminarianPanel: 'ssPanel',
    updateOwnProfile:      'updateOwnProfile',
}

export default rolesEnum