import { SETTINGS_TYPES } from "@/constant";
import { settingApi } from "@/api";

export const SettingsService = {
    get: (type, forceFetch = false) => {
        const key = getKey(type);
        let storeData = localStorage.getItem(key);

        if (!storeData || forceFetch) {
            settingApi.getAll()
                .then(response => {
                    const items = response.data.filter(item => item.type === type);
                    const generalSettings = {};
                    items.forEach(item => Object.assign(generalSettings, { [item.key]: item.value }));
                    localStorage.setItem(key, JSON.stringify(generalSettings));
                });

            // If fetching from API, return the existing data from localStorage if available
            return storeData ? JSON.parse(storeData) : null;
        }

        // Return the data from localStorage
        return JSON.parse(storeData);
    },
};

const getKey = (type) => {
    let key;
    switch (type) {
        case SETTINGS_TYPES.GENERAL:
            key = SETTINGS_TYPES.GENERAL_SETTINGS;
            break;
        case SETTINGS_TYPES.CUSTOM:
            key = SETTINGS_TYPES.CUSTOM;
            break;
        default:
            key = SETTINGS_TYPES.GENERAL_SETTINGS;
    }

    return key;
};
