import React from 'react'
import { useSelector } from 'react-redux'
import { selectorMyTimetable } from '@store/common/common.reducer'
import { TimetableService } from '../../../../../../src/services/TimetableService'
import s from './index.module.css'

const Further = () => {
  const calendar = useSelector(selectorMyTimetable)
  const lesson = React.useMemo(() => TimetableService.getAllForthcoming(calendar), [calendar])
  let nextLesson = null;
  if(lesson?.length > 0){
    nextLesson=lesson[0];
  }

  // console.log(lesson)
  // const getNextLesson = () => TimetableService.getForthcoming(lesson, 10)
  // const [nextLesson, setNextLesson] = React.useState(getNextLesson)

 
  // React.useEffect(() => {
  //   const get = () => {
  //     const newData = getNextLesson()
  //     if (nextLesson === null && newData?.id) { setNextLesson(newData) }
  //     else if (nextLesson?.id !== newData?.id) { setNextLesson(newData) }
  //   }
  //   const timerId = setInterval(get, 60000)
  //   return () => clearInterval(timerId)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [nextLesson])
 
  if (!nextLesson) return <></>
  return(
    <a href={nextLesson?.task?.link_to_meeting} target='_blank' rel="noreferrer" className={s.courseTime}>
      <div className={s.courseTimeIcon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.94615 1.09624C8.65975 0.983669 8.34025 0.983669 8.05385 1.09624L1.30562 3.74871L3.65612 4.6726C3.79784 4.51201 3.98908 4.33901 4.24569 4.1709C5.00068 3.67631 6.291 3.24276 8.49981 3.24276C9.11114 3.24276 9.65212 3.27597 10.1309 3.33449V1.56191L8.94615 1.09624ZM10.1309 4.35492V6.27827C10.1309 6.55767 10.3614 6.78417 10.6457 6.78417C10.93 6.78417 11.1605 6.55767 11.1605 6.27827V4.56666C11.6258 4.7017 11.954 4.86289 12.1828 5.01278C12.448 5.18653 12.5872 5.34963 12.6567 5.45215C12.6661 5.46596 12.6743 5.47881 12.6814 5.4906C12.6883 5.50651 12.696 5.52189 12.7043 5.53673V8.45338L8.49981 9.95576L4.2953 8.45338V5.53742C4.30417 5.52168 4.31227 5.50533 4.31953 5.48839C4.32636 5.47721 4.33412 5.4651 4.34291 5.45215C4.41246 5.34963 4.55163 5.18653 4.81685 5.01278C5.34895 4.6642 6.4183 4.25457 8.49981 4.25457C9.13258 4.25457 9.67182 4.29243 10.1309 4.35492ZM3.26562 5.60887L0.54478 4.53941C-0.181594 4.2539 -0.181592 3.24352 0.54478 2.95801L7.67144 0.156798C8.20333 -0.052266 8.79667 -0.0522658 9.32856 0.156798L16.4552 2.95801C17.1816 3.24352 17.1816 4.2539 16.4552 4.53941L13.734 5.60902V8.80773C13.734 9.02046 13.5985 9.21047 13.3951 9.28317L8.67575 10.9695C8.56209 11.0101 8.43752 11.0101 8.32386 10.9695L3.60452 9.28317C3.40106 9.21047 3.26562 9.02046 3.26562 8.80773V5.60887ZM13.3436 4.67271L15.6944 3.74871L11.1605 1.96663V3.51854C11.8594 3.689 12.3743 3.92224 12.7539 4.1709C13.0106 4.33905 13.2019 4.51209 13.3436 4.67271Z" fill="#2B2D3E"/>
        </svg>
      </div>
      <div className={s.courseTimeTitle}>
        {nextLesson?.direction}
      </div>
      <div className={s.courseTimeText}>
        {`Сегодня в ${nextLesson?.start_time?.slice(0, -3)}`}
      </div>
    </a>
  )
}


export default Further
