import classNames from "classnames";
import { Link } from "react-router-dom";
import { createDate } from "@/helpers/date";
import { declOfNum } from "@/helpers/declOfNum";
import { formateName } from "@/helpers/formateName";
import { geStatRowList } from "@/helpers/geStatRowList";
import { getAvatar } from "@/helpers/getAvatar";
import { InputTextarea } from "@UI/InputNew";
import Button from "@/components/UI/Button";
import StatRow from "@/components/UI/StatRow";
import s from "./FrontTaskCard.module.css";
import Preloader from "@/components/UI/Preloader";
import React from "react";
import { useAddAlert } from "@/hooks/useAddAlert";
import { homeWorkApi } from "@/api";

const formatDate = (d) => {
  const date = createDate({
    date: new Date(typeof d === "number" ? d * 1000 : d),
  });
  if (d) return ` ${date.dayNumber} ${date.monthGenitive}`;
  return "";
};

const FrontTaskCard = ({ data, role = "children", ...props }) => {
  if (!data) return <div className={s.wrapper}>Нет данных</div>;

  return (
    <div className={s.wrapper}>
      {role === "children" ? (
        <HeaderStudent {...data} />
      ) : (
        <HeaderTeacher {...data} />
      )}
      <Head {...data} />
      {data.type === "abstract" && <Abstract {...data} />}
      {data?.lesson?.lessonTasks && <TaskList role={role} {...data} />}
      <Action {...{ data, role, ...props }} />
    </div>
  );
};

export default FrontTaskCard;

const HeaderStudent = (props) => {
  const getTitle = () => {
    switch (props?.type) {
      case "probe":
        return "Пробник";
      case "control":
        return "Контрольная работа";
      case "unique":
        return "Уникальная домашняя работа";
      case "abstract":
        return "Конспект";
      default:
        return "Домашняя работа";
    }
  };

  // mark in checked homework
  // const getMark = () => {
  //   let mark = 1;
  //   if (props.decided_right === 0 || props.decided_right === null) {
  //     return mark;
  //   }
  //   if (props.decided_right >= 20) {
  //     mark = 2;
  //     if (props.decided_right >= 40) {
  //       mark = 3;
  //       if (props.decided_right >= 60) {
  //         mark = 4;
  //         if (props.decided_right >= 80) {
  //           mark = 5;
  //         }
  //       }
  //     }
  //   }

  //   return mark;
  // };

  return (
    <div className={s.headStudent}>
      <h2 className={s.title}>{getTitle()}</h2>

      {props.decided_right && props.decided_right !== null && (
        <span className={classNames(s.title, s.headerStudentPercent)}>
          {props.decided_right}% из 100%
          {/* {getMark()} */}
        </span>
      )}
    </div>
  );
};

const HeaderTeacher = (props) => {
  return (
    <div className={s.headerTeacher}>
      <div className={s.nameWrap}>
        <div className={classNames("ava", s.ava)}>
          <img src={getAvatar(props.children)} alt="" />
        </div>
        <h2 className={s.title}>
          {formateName(props?.children?.name, "F I")}{" "}
          {process.env.REACT_APP_DEV_MODE === "1" && (
            <span className="error">{props?.children?.id}</span>
          )}
        </h2>
      </div>

      <div className={s.headerTeacherDate}>
        {props?.completed_date && (
          <span
            className={classNames("booble booble--blue", s.headerTeacherBooble)}
          >
            Дата сдачи: <span>{formatDate(props?.completed_date)}</span>
          </span>
        )}
        {props?.expired_date && (
          <span
            className={classNames("booble booble--blue", s.headerTeacherBooble)}
          >
            Дата проверки: <span>{formatDate(props?.expired_date)}</span>
          </span>
        )}
      </div>

      {typeof props?.decided_right === "number" && (
        <span className={classNames(s.title, s.headStudentPercent)}>
          {props.decided_right}% / 100%
        </span>
      )}
    </div>
  );
};

const Head = (props) => {
  return (
    <div className={s.head}>
      {props?.direction && (
        <ValueBox title="Направление" text={props.direction.name} />
      )}
      {props?.lesson?.topic && (
        <ValueBox title="Тема" text={props.lesson.topic.name} />
      )}
      {props?.lesson && <ValueBox title="Урок" text={props.lesson.name} />}
      {props.score > 0 && (
        <span className={classNames(s.headBalls, "booble")}>
          {props.score} {declOfNum(props.score, ["балл", "балла", "баллов"])}
        </span>
      )}
    </div>
  );
};

const TaskList = (props) => (
  <div className={s.taskList}>
    <StatRow role={props.role} values={geStatRowList(props)} type={"one"} />
  </div>
);

const Abstract = (props) => (
  <div className={s.abstract}>
    {props?.files?.map((file, index) => (
      <a
        key={file.id}
        href={process.env.REACT_APP_PATH_FILE + file.file_path}
        target="_blank"
        rel="noreferrer"
      >
        <button className="btn">Открыть файл №{index + 1}</button>
      </a>
    ))}

    <InputTextarea
      className={s.textarea}
      {...{
        value: props.comment ?? "Учитель не дал комментарий",
        disabled: true,
        title: "Комментарий учителя",
      }}
    />
  </div>
);

const Action = ({ data, ...props }) => {
  let text = "Перейти к ";
  switch (data.type) {
    case "probe":
      text += "пробнику";
      break;
    case "control":
      text += "контрольной";
      break;
    case "sop":
      text += "СОП";
      break;
    default:
      text += "дз";
  }

  const link = props.link ? props.link : `/home-work/work-${data.id}`;

  const [loadingDel, setLoadingDel] = React.useState(false);
  const addAlert = useAddAlert();

  const removeHW = (id) => {
    setLoadingDel(true);
    homeWorkApi.remove({ id }).then((res) => {
      setLoadingDel(false);

      if (res.data.status === "success") {
        props.setState((prev) => prev?.filter((item) => item.id !== id));
        addAlert({ title: "Домашнее задание удалено" });
      } else
        addAlert({
          type: "error",
          title:
            "Упс! Ошибка. Попробуйте ещё раз или обратитесь к администратору",
        });
    });
  };

  const markText = `Оценка: ${data?.mark}`;
  return (
    <div className={s.action}>
      {data.type !== "abstract" && <>
          <Btn link={link} text={text} />
          {data?.mark && <Button name={markText} />}
          </>
      }
      {data?.lesson?.redo_homework === 1 &&
        props.role === "children" &&
        data?.status !== 10 &&
        data?.status !== 0 && (
          <Btn link={`${link}?redo=ok`} text={"Перерешать дз"} />
        )}

      <div className={s.rightBtn}>
        {data?.status === 5 &&
          props.role === "teacher" &&
          data?.attempt > 1 && (
            <span className={classNames("booble booble--blue", s.actionBooble)}>
              Перерешено: {+data?.attempt - 1}
            </span>
          )}
        {props?.remove &&
          data.status < 6 &&
          (loadingDel ? (
            <Preloader />
          ) : (
            <Button action={() => removeHW(data.id)} styles="red">
              Удалить ДЗ
            </Button>
          ))}
        {data?.status === 10 && (
          <span className={classNames("booble booble--blue", s.actionBooble)}>
            Оценка: {data?.mark}
          </span>
        )}
        {data?.deadline_date && (
          <span className={classNames("booble booble--blue", s.actionBooble)}>
            Выполнить до {formatDate(data?.deadline_date)}
          </span>
        )}
      </div>
    </div>
  );
};

const Btn = ({ link, text }) => (
  <Link to={link} className={classNames("btn btn--yellow", s.actionBooble)}>
    {text}
  </Link>
);

const ValueBox = ({ title, text }) => (
  <div className={s.valueBox}>
    <span className={s.valueBoxTitle}>{title}</span>
    <span className={classNames("booble", s.valueBoxBooble)}>{text}</span>
  </div>
);
