import React from 'react';
import { useSelector } from 'react-redux';
import "swiper/css";
import {
	controlApi,
	courseAPI,
	homeWorkApi,
	lessonAbstractAPI,
	probeApi,
} from '@api';
import { useLocalStorage } from '@hooks';
import { useLoad } from '@hooks/useLoad';
import { useLoadMore } from '@hooks/useLoadMore';
import { selectorMyDirectionsSelect } from '@store/common/common.reducer';
import { selectProfile } from '@store/profile.reducer';
import HomeWorkPage from './HomeWorkPage';

const HomeWorkPageContainer = () => {
	const type = useTypeHW();
	const status = useStatusHW(type);
	const filters = useFiltersHW(status.get);
	const tasks = useTasksHW(type.get, filters.get);
	return (
		<HomeWorkPage
			{...{
				type,
				status,
				filters: filters.items,
				reset: filters.reset,
				tasks,
			}}
		/>
	);
};

export default HomeWorkPageContainer;

// типы работы
const useTypeHW = () => {
	const typeItems = {
		homeWork: 'Домашняя работа',
		probe: 'Пробник',
		control: 'Контрольная',
		abstract: 'Конспект',
	};
	const [typeState, setTypeState] = useLocalStorage(
		'homeWorkTabType',
		Object.keys(typeItems)[0]
	);
	return { get: typeState, set: setTypeState, items: typeItems };
};

// статус домашней работы
const useStatusHW = (type) => {
	const statusItems = {
		0: 'Не выполнено',
		5: 'Выполнено',
		10: 'Проверенно',
	};
	if (type.get === 'abstract') delete statusItems['0'];
	const [typeState, setTypeState] = useLocalStorage(
		'homeWorkTabStatus',
		Object.keys(statusItems)[0]
	);
	return { get: typeState, set: setTypeState, items: statusItems };
};

// фильтры (направление и курсы)
const useFiltersHW = (status) => {
	const [filterDirection, setFilterDirection] = React.useState(null);
	const initFilters = { course: null };
	const [filtersState, setFilterState] = React.useState(initFilters);

	const direction = {
		value: filterDirection,
		onChange: setFilterDirection,
		placeholder: 'Направление',
		options: useSelector(selectorMyDirectionsSelect)
			.filter((item, index, self) => index === self.findIndex(t => t.value === item.value)),
	};

	const {
		state: courses,
		getState: getCourses,
		reset: resetCourse,
	} = useLoad({
		api: courseAPI.getAll,
		params: { filter: [`[direction_id]=${filterDirection}`] },
		key: 'data',
		initState: [],
		last: false,
	});

	React.useEffect(() => {
		if (filtersState.course) setFilterState(initFilters);
		if (filterDirection) getCourses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterDirection]);

	const course = {
		value: filtersState.course,
		onChange: (e) => setFilterState((prev) => ({ ...prev, course: e })),
		placeholder: filterDirection ? 'Курс' : 'Нет направления',
		options: courses.map(({ name: label, id: value }) => ({
			label,
			value,
		})),
	};

	const list = [];
	if (status === '0')
		list.push(['[status][in][]=0'], ['[status][in][]=1'], ['[status][in][]=2']);
	else list.push(`[status][eq]=${status}`);
	if (filtersState.course) list.push(`[course_id][eq]=${filtersState.course}`);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const filterList = React.useMemo(() => list, [status, filtersState]);

	const reset = () => {
		setFilterState(initFilters);
		setFilterDirection(null);
		resetCourse();
	};

	return { get: filterList, reset, items: { direction, course } };
};

// получение самих работ
const useTasksHW = (type, filter) => {
	const { id } = useSelector(selectProfile);

	const apiParams = {
		homeWork: {
			api: homeWorkApi.get,
			params: {
				id,
				filter,
				expand: [
					'lesson.lessonTasks',
					'lesson.topic',
					'direction',
					'homeWorkResults',
					'lesson.lessonTasks.task',
					'homeWorkUniqueTasks.task',
				],
			},
		},
		probe: {
			api: probeApi.getMy,
			params: {
				filter,
				expand: [
					'work',
					'work.material.course',
					'work.material.course.direction',
					'work.material.probeTasks',
					'work.material.probeTasks.task',
					'homeWorkResults',
				],
			},
		},
		control: {
			api: controlApi.get,
			params: {
				filter: [...filter, `[children_id][eq]=${id}`],
				expand: [
					'work',
					'work.material.course',
					'work.material.course.direction',
					'work.material.controlTasks',
					'work.material.controlTasks.task',
					'controlWorkResults',
				],
			},
		},
		abstract: {
			api: lessonAbstractAPI.get,
			params: {
				filter,
				expand: ['verifiedBy', 'files', 'plan.lesson.topic.course.direction'],
			},
		},
	};

	const {
		state: res,
		getState,
		ref,
		loadingAll,
		loading,
	} = useLoadMore({ ...apiParams[type], last: false });

	let state;

	switch (type) {
		case 'probe':
			state = res?.map((item) => ({
				id: item?.id,
				type: 'probe',
				children: item?.children,
				score: +item?.score,
				completed_date: item?.completed_date,
				expired_date: item?.expired_date,
				direction: item?.work?.material?.course?.direction ?? {},
				lesson: {
					...item?.work?.material,
					lessonTasks: item?.work?.material?.probeTasks,
				},
				group_id: item?.work?.group_id,
				course_id: item?.work?.course_id,
				work_id: item?.work_id,
				decided_right: item?.decided_right,
				homeWorkResults: item.results,
			}));
			break;
		case 'control':
			state = res?.map((item) => ({
				id: item?.id,
				type: 'control',
				children: item?.children,
				score: +item?.score,
				completed_date: item?.completed_date,
				expired_date: item?.expired_date,
				direction: item?.work?.material?.course?.direction ?? {},
				lesson: {
					...item?.work?.material,
					lessonTasks: item?.work?.material?.controlTasks,
				},
				group_id: item?.work?.group_id,
				course_id: item?.work?.course_id,
				work_id: item?.work_id,
				decided_right: item?.decided_right,
				homeWorkResults: item.controlWorkResults,
			}));
			break;
		case 'abstract':
			state = res?.map((item) => ({
				...item,
				type: 'abstract',
				direction: item?.plan?.lesson?.topic?.course?.direction ?? {},
				lesson: { ...item?.plan?.lesson },
			}));
			break;
		default: {
			state = res?.map((item) => {
				if (item?.homeWorkUniqueTasks?.length < 1) return item;
				return {
					...item,
					type: 'unique',
					lesson: {
						lessonTasks: item?.homeWorkUniqueTasks,
						name: 'Уникальное',
					},
				};
			});
			break;
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => {
		getState();
	}, [type, filter]);

	const empty = !loading && state?.length === 0 ? 'Таких работ нет' : null;

	return { get: state, ref, loading, loadingAll, empty };
};
