import { useEffect, useMemo, useState } from 'react'
import { homeTaskApi } from '../../../api'
import { usePaginationBottom } from '../../../hooks/usePaginationBottom'
import Task from './Task'
import s from './TaskList.module.css'

const addFilterID = arrID => {
  if (typeof arrID === 'object' && arrID.length > 0)
    return arrID?.map(item => `[id][in][]=${item?.id}`)
  return []
}


export const NewTaskList = ({ taskList, filters, category, newTask, setNewTask = () => { }, number = false, setCountTask = () => { }, notOutputWithoutFilters = false, ...props }) => {

  const getHomeWork = () => homeTaskApi.getAll({ filter: [...filters, ...addFilterID(taskListId)], expand: ['homeTaskFiles', 'numberExam'] }).then(res => {
    pushRes(res)
    setCountTask(res?.data?._meta?.totalCount)
    setState(res.data.data)
  })


  filters = useMemo(() => filters ? filters : [], [filters])

  const [status, setStatus] = useState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setNewTask({}) }, [status])


  const taskListId = taskList?.filter(item => item.id !== undefined)?.map(item =>
    item?.task?.id !== undefined ? { id: item?.task?.id, sorting: item?.sorting } : { id: item?.id, sorting: item?.sorting })
  const taskListNew = taskList?.filter(item => item.devID !== undefined)

  const [state, setState] = useState([])

  const { pushRes } = usePaginationBottom({
    addList: res => {
      setCountTask(res?.data?._meta?.totalCount)
      setState([...state, ...res.data.data])
    }
  })


  const getTasks = () => (notOutputWithoutFilters && filters?.length < 1) ? [] : (typeof taskListId === 'object' && taskListId?.length < 1) ? [] : getHomeWork()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getTasks() }, [filters, status, taskList])


  const sort = (a, b) => (a?.sorting !== undefined) ? (a.sorting > b.sorting) ? 1 : -1 : (a.id > b.id) ? -1 : 1
  const sortNew = (a, b) => (a.devID > b.devID) ? -1 : 1

  let globalIndex = 0
  
  return <div className={s.list}>
    {newTask?.type && <Task props={{ ...newTask, category: 'edit' }} setNewTask={setNewTask} setStatus={setStatus} />}

    {
      taskList !== undefined
        ? <>
          {taskListNew?.length > 0 && taskListNew?.sort(sortNew)?.map(task => {
            globalIndex++
            return <Task key={task.devID} props={{ ...task, category, 'direction_id': props.direction }} title={number ? globalIndex : false} {...props} setStatus={setStatus} />
          })}
          {taskListId?.length > 0 && state.length > 0 && taskListId?.sort(sort)?.map(task => {
            const data = state.find(item => item.id === task.id)
            globalIndex++
            return data && <Task key={task.id} props={{ ...data, category }} title={number ? globalIndex : false} {...props} setStatus={setStatus} />
          })}

        </>
        : state?.sort(sort)?.map(task => {
          globalIndex++
          return <Task key={task.id} props={{ ...task, category }} title={number ? globalIndex : false} {...props} setStatus={setStatus} />
        })
    }
  </div>
}
