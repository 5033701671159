import s from './MainChatsView.module.css';
import {useInput} from "@/hooks";
import Search from "@UI/Search";
import {useEffect, useRef, useState} from "react";
import {AddNewChatIcon, CloseIcon, CompressIcon} from "@/utils/icons";
import {chatApi} from "@/api";
import ChatViewList from "@common/Chat/components/ChatViewList";
import {useClickAway} from "react-use";
import UserList from "@common/Chat/components/UserList";
import {useSelector} from "react-redux";
import {selectProfile} from "@store/profile.reducer";
import {SettingsService} from "@/services/SettingsService";
import {SETTINGS_TYPES} from "@/constant";
import rolesEnum from "@/constant/rolesEnum";
const MainChatPage = ({setPage, activeUsers, unReadChats}) => {
    const [bindSearch, valueSearch, reset] = useInput('')
    const [users, setUsers] = useState([]);
    const [isShowUsers, setIsShowUsers] = useState(false);
    const [chats, setChats] = useState([]);
    const userRef = useRef(null);
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);
    const [state, setState] = useState({
        search: null,
    });
    const profile = useSelector(selectProfile);

    const canStartChat = () => {
        const childrenCanStartChat = SettingsService.get(SETTINGS_TYPES.GENERAL)?.isChildrenCanStartChat === '1'

        return profile.role !== rolesEnum.children || childrenCanStartChat
    }

    useClickAway(userRef, () => {
        setIsShowUsers(false);
    });

    useEffect(() => {
        chatApi.getChatList({
            limit: 10000,
        })
            .then((res) => {
                if (res?.data) {
                    let result = res?.data.sort((a,b) =>  (a.isFixed === true && !b.isFixed) ? -1 : ((b.isFixed === true && !a.isFixed) ? 1 : 0));
                    setChats(result)

                }
            }).then(() => setIsNeedUpdate(false))
    }, [isNeedUpdate])
    const getUsers = () => {
        chatApi.getUserList({
            search: valueSearch,
        })
            .then(response => {
                if (response?.data) {
                    setUsers(response?.data.slice(0,20))
                }
            })
        ;
    }

    useEffect(() => {
        getUsers()
    }, [state]);

    const dataSearch = {
        bind: bindSearch,
        onChange: () => {
            setState({
                search: valueSearch,
            })
        }
    }

    const handleCloseUsersList = () => {
        setIsShowUsers(false)
        reset()
    }

    return (
        <div className={s.page}>
            <div className={s.header}>
                {canStartChat() && <>
                    <Search
                      placeholder="Введите ФИО собеседника"
                      className={s.search} {...dataSearch}
                      withClose={false}
                      onFocus={() => setIsShowUsers(true)}
                    />
                    {isShowUsers && <button className={s.close} onClick={handleCloseUsersList}><CloseIcon/></button>}
                    <button className={s.addBtn} onClick={() => setPage('create')}>
                        <AddNewChatIcon width={'36'} height={'36'}/>
                    </button>
                    <span className={s.desc}>Cоздать чат</span>
                </>}
            </div>
            {isShowUsers && <UserList users={users} setPage={setPage} activeUsers={activeUsers}/>}
            {!isShowUsers && <ChatViewList chats={chats} setPage={setPage} setIsNeedUpdate={setIsNeedUpdate} unReadChats={unReadChats}/>}
            <div className={s.footer}>
                <div className={s.toggleView} onClick={() => setPage('default')}>
                    <CompressIcon width={'20'} height={'20'}/>
                </div>
            </div>
        </div>

    )
}

export default MainChatPage;